import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion'

import { queryService } from '@wap-client/services'
import { useApp, useWidth } from '@wap-client/core'

import { Column, Container, Row } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'

import { CategoryProps } from './types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode } from 'swiper/modules'

const rowTopVariant = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    x: -1500,
    transition: {
      duration: 1,
    },
  },
}

const rowBottomVariant = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    x: 1500,
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
}

const Category: React.FunctionComponent<CategoryProps> = ({
  title,
  desc,
  categories = [],
  festivalID,
}) => {
  const app = useApp()
  const width = useWidth()

  const targetRef = useRef<HTMLDivElement>(null)

  const isInView = useInView(targetRef, {
    once: true,
    margin: '-30% 0px',
  })

  const [eventCounts, setEventCounts] = useState<{
    [categoryId: string]: number
  }>({})

  const getEvents = useCallback(
    async (categoryId: string) => {
      const params = new URLSearchParams(`category=${categoryId}`)
      const date = new Date().toISOString()

      if (festivalID) {
        params.append('festivalId', festivalID)
      }

      if (date) {
        params.append('date', date)
      }

      try {
        const response = await queryService.run(app.environment, {
          name: 'kty-etkinlikler-kategori-veri-sorgusu',
          language: app.language,
          query: params.toString(),
        })

        if (response && response.data && Array.isArray(response.data)) {
          const count = response.data.length
          setEventCounts((prevState) => ({
            ...prevState,
            [categoryId]: count,
          }))
        }
      } catch (err) {
        console.log(err)
      }
    },
    [app.environment, app.language]
  )

  useEffect(() => {
    categories?.forEach((category) => {
      getEvents(category.id)
    })
  }, [categories, getEvents])

  return width < 1025 ? (
    <section className="categories" ref={targetRef}>
      <Container size="extended">
        <motion.div
          variants={rowTopVariant}
          animate={isInView ? 'visible' : 'hidden'}
        >
          <Row>
            <Swiper
              slidesPerView={1}
              freeMode={{
                enabled: true,
                sticky: false,
              }}
              loop={true}
              width={248}
              spaceBetween={10}
              modules={[FreeMode, Autoplay]}
            >
              {categories.map((category, index) => (
                <SwiperSlide key={index}>
                  <div className="categories__item">
                    <Anchor
                      href={`${
                        app.settings.routes['events']?.href
                      }?categoryId=${category?.id}&${
                        festivalID && `festivalId=${festivalID}`
                      } `}
                    >
                      <figure>
                        <Image {...category.image} alt={category.title} />
                      </figure>
                      <div className="categories__item__head">
                        <div className="title">{category.title}</div>
                        <div className="count">
                          <span>{eventCounts[category.id]}</span>
                          {app.settings.translations['festivalEvents']}
                        </div>
                      </div>
                    </Anchor>
                  </div>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                <div className="categories__item categories__item--all">
                  <div className="categories__item__head">
                    <div className="title">{title}</div>
                    {desc && <p dangerouslySetInnerHTML={{ __html: desc }} />}
                    <Icon name="arrow-right" />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </Row>
        </motion.div>
      </Container>
    </section>
  ) : (
    <section className="categories" ref={targetRef}>
      <Container size="extended">
        <motion.div
          variants={rowTopVariant}
          animate={isInView ? 'visible' : 'hidden'}
        >
          <Row>
            <Column auto>
              <div className="categories__item categories__item--all">
                <div className="categories__item__head">
                  <div className="title">{title}</div>
                  {desc && <p dangerouslySetInnerHTML={{ __html: desc }} />}
                  <Icon name="arrow-right" />
                </div>
              </div>
            </Column>
            {categories?.slice(0, 4).map((category, index) => (
              <Column key={index} auto>
                <div className="categories__item">
                  <Anchor
                    href={`${app.settings.routes['events']?.href}?categoryId=${
                      category?.id
                    }&${festivalID && `festivalId=${festivalID}`} `}
                  >
                    <figure>
                      <Image {...category.image} alt={category.title} />
                    </figure>
                    <div className="categories__item__head">
                      <div className="title">{category.title}</div>
                      <div className="count">
                        {eventCounts[category.id] > 0 ? (
                          <>
                            <span>{eventCounts[category.id]}</span>
                            {app.settings.translations['festivalEvents']}
                          </>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </Anchor>
                </div>
              </Column>
            ))}
          </Row>
        </motion.div>
        <motion.div
          variants={rowBottomVariant}
          animate={isInView ? 'visible' : 'hidden'}
        >
          <Row>
            {categories?.slice(4, 9).map((category, index) => (
              <Column key={index} auto>
                <div className="categories__item">
                  <Anchor
                    href={`${app.settings.routes['events']?.href}?categoryId=${
                      category?.id
                    }&${festivalID && `festivalId=${festivalID}`} `}
                  >
                    <figure>
                      <Image {...category.image} alt={category.title} />
                    </figure>
                    <div className="categories__item__head">
                      <div className="title">{category.title}</div>
                      <div className="count">
                        {eventCounts[category.id] > 0 ? (
                          <>
                            <span>{eventCounts[category.id]}</span>
                            {app.settings.translations['festivalEvents']}
                          </>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </Anchor>
                </div>
              </Column>
            ))}
          </Row>
        </motion.div>
      </Container>
    </section>
  )
}

export default Category
